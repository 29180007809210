/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

import PerfectScrollbar from 'perfect-scrollbar';
import Swal from 'sweetalert2';

var moment = require('moment');
window.moment = moment;

window.Swal = Swal;

$(function () {
    $('.ps-container').each(function(){
        const ps = new PerfectScrollbar($(this)[0]);
    });   

    window.resetInput = (element) => {
        var tag = $(element).prop("tagName"), type = $(element).attr("type");

        if(tag === 'INPUT' || tag === 'TEXTAREA'){
            if(type === 'radio'){
                $(element).prop('checked', function() {
                return this.defaultChecked;
                });
            } else {
                $(element).val(function(){
                return this.defaultValue;
                });
            }
        } else if(tag === 'SELECT') {
            $(element).find('option').prop('selected', function() {
                return this.defaultSelected;
            });
        }
    }

    $.validator.setDefaults({
      errorElement: 'span',
        errorPlacement: function (error, element) {
          if (element.parents('.input-group').length > 0) {
              error.addClass('invalid-feedback');
              element.parents('.input-group').after(error);
          } else if (element.parents('.bootstrap-select').length > 0) {
              error.addClass('invalid-feedback');
              element.parents('.bootstrap-select').after(error);
          } else if (element.parents('.dropzone-wrapper').length > 0) {
              error.addClass('invalid-feedback');
              element.parents('.dropzone-wrapper').after(error);
          } else {
              error.addClass('invalid-feedback');
              element.after(error);
          }
      },
      highlight: function (element, errorClass, validClass) {        
          if ($(element).parents('.input-group').length > 0) {
              $(element).parents('.input-group').addClass('is-invalid');
          } else if ($(element).parents('.bootstrap-select').length > 0) {
              $(element).parents('.bootstrap-select').addClass('is-invalid');
          } else if ($(element).parents('.dropzone-wrapper').length > 0) {
              $(element).parents('.dropzone-wrapper').addClass('is-invalid');
          } else {
              $(element).addClass('is-invalid');
          }
      },
      unhighlight: function (element, errorClass, validClass) {
          if ($(element).parents('.input-group').length > 0) {
              $(element).parents('.input-group').removeClass('is-invalid');
          } else if ($(element).parents('.bootstrap-select').length > 0) {
              $(element).parents('.bootstrap-select').removeClass('is-invalid');
          } else if ($(element).parents('.dropzone-wrapper').length > 0) {
              $(element).parents('.dropzone-wrapper').removeClass('is-invalid');
          } else {
              $(element).removeClass('is-invalid');
          }
      }
    });
    
    window.scrollBack = (target) => {
        $('html, body').animate({
            scrollTop: $(target).offset().top - 150
        }, 500);
    }
    
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl)
    })

    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (!entry.isIntersecting) {
                return;
            }

            entry.target.classList.add('is-inViewport');
            if (!entry.target.hasAttribute("data-viewport-keep")) {
                observer.unobserve(entry.target);
            }
        });
    });

    const obsOptions = {
        root: null,
        rootMargin: '0px',
        threshold: 0.3
    };

    const ELs_inViewport = document.querySelectorAll('[data-inviewport]');
    ELs_inViewport.forEach(EL => {
        observer.observe(EL, obsOptions);
    });

    window.ucword = (target) => {
        var str = target.val().replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
        target.val(str);
    }

    window.filtter_select_ajax = (target, data, url) => {
        $(target).addClass('loading');
        $(target).prop('disabled', true);

        $.ajax({
            url: url,
            data: data,
            success: function (e) {
                $(target).removeClass('loading');
                $(target).prop('disabled', false);
                $(target).html(e);  
                if($(target).hasClass('selectpicker')){
                    $(target).selectpicker('refresh');
                }           
            }
        });
    }

    window.clearSelect = (e) => {
        $(e).find('option:not([value=""])').not().remove();
    }
    
    window.getData = (target, loader, url, data) => {
      $(target).html(loader);

      $.ajax({
        type: "GET",
        url: url,
        data: data,
        success: function(data)
        {
          $(target).html(data);
        }
      });
    }

    window.rupiah = (nStr, inD = '.', outD = '.', sep = ',') => {
        nStr += '';
        var dPos = nStr.indexOf(inD);
        var nStrEnd = '';
        if (dPos != -1) {
            nStrEnd = outD + nStr.substring(dPos + 1, nStr.length);
            nStr = nStr.substring(0, dPos);
        }
        var rgx = /(\d+)(\d{3})/;
        while (rgx.test(nStr)) {
            nStr = nStr.replace(rgx, '$1' + sep + '$2');
        }
        var result = nStr + nStrEnd;
        return result;
    }

    window.number = (bilangan) => {
        var number_string = bilangan.toString(),
            sisa = number_string.length % 3,
            rupiah = number_string.substr(0, sisa),
            ribuan = number_string.substr(sisa).match(/\d{3}/g);

        if (ribuan) {
            separator = sisa ? ',' : '';
            rupiah += separator + ribuan.join(',');
        }
        return rupiah;
    }

    window.touppercase = (element) => {
        var str = $(element).val().toLowerCase();
        var lowercase = (str + '').replace(/^(.)|\s+(.)/g, function($1) {
            return $1.toUpperCase()
        });
        $(element).val(lowercase);
    }

    window.getFileExtension = (filename) => {
        return filename.split('.').pop();
    }

    window.numberOnly = (e) => {
        // Allow: backspace, delete, tab, escape, enter and .
        if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 190]) !== -1 ||
            // Allow: Ctrl+A, Command+A
            (e.keyCode === 65 && (e.ctrlKey === true || e.metaKey === true)) ||
            // Allow: home, end, left, right, down, up
            (e.keyCode >= 35 && e.keyCode <= 40)) {
            // let it happen, don't do anything
            return;
        }
        // Ensure that it is a number and stop the keypress
        if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
            e.preventDefault();
        }

        if ($(e).val() < 0 || isNaN($(e).val())) {
            $(e).val(0);
        }
    }

    window.Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    
    window.Msg = Swal.mixin({
        customClass: {
            popup: 'radius-25',
            confirmButton: 'rounded-pill px-4'
        }
      })
});